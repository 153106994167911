<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ getCase.FileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <h2>Case Media</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div style="width: fit-content">
          <router-link
            :to="'/cases/' + CaseID + '/detail'"
          >
            <h3 style="text-decoration: underline">
              Case {{ getCase.FileNumber }}
            </h3>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col
        v-for="item in Videos"
        :key="item.FileID"
        md="3"
      >
        <div class="video-container mb-1">
          <div class="video-content">
            <img :src="item.URL">
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      CaseID: "",
      Videos: [],
      ActionsToInclude: [],
      ActionIDs: [],
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
    }),
  },
  created() {
    this.CaseID = this.$route.params.caseId;
    this.loadActionLogs();
    console.log(this.getCase)
  },
  methods: {
    loadActionLogs() {
      apiService
          .get("actions/case/" + this.CaseID)
          .then((res) => {
            this.ActionsToInclude = res.data;
            this.useTheseCaseActions();
          });
    },
    useTheseCaseActions() {
      this.ActionsToInclude.map(item => {
        this.ActionIDs.push(item.ActionID)
      })
      const postData = {
        ActionIDs: this.ActionIDs,
      }
      apiService
          .post("action/activity/media", postData)
          .then(res => {
            this.Videos = res.data
          })
    },
  }
}
</script>

<style scoped>
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
  position: relative;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
}
</style>